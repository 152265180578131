@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Cinzel+Decorative:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth;
    transition: all ease 800s;
}

body {
    position: relative;
}

.logo-container {
    font-family: 'Cinzel Decorative', cursive;
    letter-spacing: 2px;
}

h2 {
    font-family: 'Poppins';
}